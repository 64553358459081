.pc{
    display: block;
}
.movil{
    display: none;
}

.card-body{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background: #ffffff;
    margin:10px;
}
.card-body i{
    color: #00a6b4;
    font-size: 45px;
    padding: 20px;
}
.card-body p{
    color: rgb(102,83,83);
    text-align: justify;
    
}
    
.card-body .card-title{
    color: rgb(68,68,68);
    font-weight: bold;
    border-radius: 20px;
    border-color: rgb(68,68,68);
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}
.site-heading h1{
    margin-bottom: 40px;
}
.post .post_pad{
    padding-top:3rem;
}

@media only screen and (max-width: 767px) {
    .pc{
        display: none;
    }
    .movil{
        display: block;
    }
    .post .post_pad{
        padding-top:10px;
    }
    .card-body {
        margin:50px;
    }
    /*
    .card-body{
        display: flex;
        flex-direction: row;
    }
    */
    .card-body p{
    padding-left: 20px;
    padding-right: 20px;
    }

    .card-body .card-title{
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    
    
    .encabezado .fondo3 {
       
            background: url("./images/rayon.png") !important;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            background-size: contain !important;
        
    }
    .encabezado .fondo3 h1{ 
        color: rgb(255,255,255);
        text-align: center;
    }
    .blog_title {
        font-size: 1rem;
    }
    .site-heading h1{
        font-size: 1.7rem;
        margin-bottom: 0px;
    }
}
.card-body .btn-info{
    background-color: #00a6b4;
}

video {
    width: 100%;
    aspect-ratio: 16/9;
}