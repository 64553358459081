.movimientos .table th, .table td{
    padding: 4px;
}
.movimientos .subtitulo{
    color: #00a6b4;
    margin-left: 40px;
    font-weight: lighter;
}
.movimientos .pieza{
 height: 40vh;
}
.movimientos table thead tr{
    background: #60757e;color: rgb(255,255,255);border-color: rgba(255,255,255,0);
}
.movimientos table thead tr td{
    text-align: center;padding: 10px;border-width: 0px;background: #84a6b4;
}
.movimientos table tbody{
    background: rgba(255,255,255,0.94);
}
.movimientos table tbody tr{
    height: 34px;
}
.movimientos table tbody tr td{
    height: 34px;padding: 6px;background: #ced8dc;text-align: left;font-size: 14px;padding-left: 25px;
}
.movimientos .btn-info:hover{
    background-color: red;
}
.movimientos .fondo_rayon{
    background: url("./images/rayon.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
}
.movimientos .referencias {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.movimientos .referencias img {
    width: 40px;
    height: 40px;
}   

@media (max-width: 767px)  {
    .movimientos .referencias img {
        width: 25px;
        height: 25px;
    }
    
    .movimientos .tableros {
        width: 90vw;

    }    
    .movimientos .subtitulo{
        margin-left: 0px;
    }
} 

/* responsive tables */
@media only screen and (max-width: 800px) {
	/* Force table to not be like tables anymore */
    .movimientos table tbody tr{
        height: 250px;
    }

	.table-bordered {
		border:0px;
	}

	#no-more-tables-mov{
		margin-bottom: 135px;
	}
	#no-more-tables-mov td{
		min-height: 50px;
	} 

	#no-more-tables-mov table, 
	#no-more-tables-mov thead, 
	#no-more-tables-mov tbody, 
	#no-more-tables-mov th, 
	#no-more-tables-mov td, 
	#no-more-tables-mov tr { 
		display: block; 
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	#no-more-tables-mov thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	#no-more-tables-mov tr { border: 1px solid #ccc; margin-bottom: 30px; }

	#no-more-tables-mov td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
		white-space: normal;
		text-align:left;
		max-width: 100%;
	}

	#no-more-tables-mov td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		padding-right: 10px; 
		white-space: nowrap;
		text-align:left;
		font-weight: bold;
	}
	#no-more-tables-mov td:before { content: attr(data-title); }


}