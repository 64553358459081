.footer-dark img {
    width: 60px;
    height: 30px;
    margin-right: 10px;
}
.footer-dark #logo_footer{
    text-align: center;
}
@media only screen and (min-width: 992px) {
    .footer-dark #logo_footer{
        text-align: right;
    }
}
.footer-dark #marca_footer{
    text-align: center;
    font-size: .75rem;
    padding-top: 8px;
}
@media only screen and (min-width: 992px) {
    .footer-dark #marca_footer{
        text-align: left;
    }
}