.encabezado .fondo1{
    padding-top: 50px;
    padding-bottom: 50px;
    background: url("../images/fondo-mega.jpg");
    background-position: center;
    background-size: cover;
}
.encabezado .fondo1 h1{ 
    color: rgb(255,255,255);
    text-align: center;
} 

.encabezado .fondo2{
    padding-top: 50px;
    padding-bottom: 50px;
    /*background: url("../images/fondo-reglas.jpg");*/
    background: url("../images/fondo2_mobile.jpg");
}
.encabezado .fondo2 h1{ 
    color: rgb(255,255,255);
    text-align: center;
}  
.fondo_principal {
    background-color:#181c33;
}  
.nav-link{
    color:white !important;
}
