body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.banner{
  height: 80px;
  background-color: #00a6b4;
}
.banner_content{
  margin-bottom: 0px;
  padding-top: 20px;
  padding-left:20px;
  padding-right:20px;
  text-align: center;
  color: rgb(255,255,255);
  line-height: 1.5rem;
}
.post .img-thumbnail{
  border: none; 
  padding: 0px;
}

.post h2{
  color: rgb(55,153,189);
}

.newsletter {
  background: #ced8dc;
}
.newsletter .banner{
  background: #ced8dc;
  height: 250px;
}

.newsletter .banner h2{
  color: #34393e;
  margin-top: 60px;
  font-size: 24px;
  padding-left:10px;
  padding-right:10px;
}

.newsletter .banner .btn-newsletter{
  margin-bottom: 0px;
  background: #00a6b4;
  font-size: 14px;
  color: rgb(255,255,255)
}
.newsletter .banner .intro {
  font-size: 16px;
  padding-left: 20vw;
  padding-right: 20vw;
  height: 50px;
}
.newsletter .banner #email{
    width: 450px;
    background: rgb(255,255,255);
    font-size: 16px;
} 
.newsletter .banner .form-inline {
  margin-top:40px;
}
#popup{
  margin: auto;
  width: 70vw;  
  max-width: 70vw;
}

@media only screen and (max-width: 767px) {
  #popup{
    margin: 0;
    width: 100vw;
    max-width:100vw;
  }
  .masthead h1 {
    font-size: 1.8rem;
  }
  .newsletter .banner .intro {
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
    height: auto;
  } 
  .newsletter .banner .form-inline {
    margin-top:0;
  }  
  .newsletter .banner #email {
    width:80vw;
  }  
  .newsletter .banner .btn-newsletter {
    margin-top: 5px;
  }  
  .banner{
    height: 120px;
  }
  .banner_content{
    font-size: 1.2rem;
  }  

}
