.fondo3{
    background: url(../images/fondo-sala3.jpg);
}
table {
    margin-top: 15px;
	padding:5px;
}
table #code {
	font-size: 12px;
}	

.centrar {
	text-align: center;
}
/* responsive tables */
@media only screen and (max-width: 800px) {
	/* Force table to not be like tables anymore */

	.table-bordered {
		border:0px;
	}

	#no-more-tables{
		margin-bottom: 135px;
	}
	#no-more-tables td{
		min-height: 50px;
	} 

	#no-more-tables table, 
	#no-more-tables thead, 
	#no-more-tables tbody, 
	#no-more-tables th, 
	#no-more-tables td, 
	#no-more-tables tr { 
		display: block; 
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	#no-more-tables thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	#no-more-tables tr { border: 1px solid #ccc; margin-bottom: 30px; }

	#no-more-tables td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 30%; 
		white-space: normal;
		text-align:left;
		max-width: 100%;
	}

	#no-more-tables td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		padding-right: 10px; 
		white-space: nowrap;
		text-align:left;
		font-weight: bold;
	}
	#no-more-tables td:before { content: attr(data-title); }


}

@media only screen and (max-width: 992px) {
    .btn-danger{
        font-size: 1rem;
    }
}